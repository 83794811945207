import React, { useContext, useEffect, useState } from "react"
import { SectionTitle, SectionTitle2 } from "../components/styled"
import WrapperRight from "../components/WrapperRight"

import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import { Seo } from "../components/seo"
import scrollTo from "gatsby-plugin-smoothscroll"
import Contact from "../components/Contact"
import ContactForm from "../components/ContactForm"
import { SearchContext } from "../context/SearchContext"

const AboutMexico = () => {
  const { setText, setType, text, type } = useContext(SearchContext)
  const [wballoon2, setWBalloon2] = useState()
  const [wballoon3, setWBalloon3] = useState()
  const [hballoon1, setHBalloon1] = useState()
  const [hballoon2, setHBalloon2] = useState()
  const [hballoon3, setHBalloon3] = useState()
  const [balloondis, setBalloonDis] = useState(true)
  useEffect(() => {
    setText({ text: text.text })
    setType({ type: type.type })
    const fetchData = async () => {
      setHBalloon1(document.getElementById("balloon1").offsetHeight)
      setWBalloon2(document.getElementById("balloon2").offsetWidth)
      setHBalloon2(document.getElementById("balloon2").offsetHeight)
      setWBalloon3(document.getElementById("balloon3").offsetWidth)
      setHBalloon3(document.getElementById("balloon3").offsetHeight)
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const queryImage = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)|(webp)/" }
          relativeDirectory: { eq: "about-mexico" }
        }
        sort: { order: ASC, fields: name }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 1800, jpegQuality: 100, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  `)
  const images = queryImage.allFile.nodes
  const balloon1 = require("../images/about-mexico/balloon1.svg")
  const balloon2 = require("../images/about-mexico/balloon2.svg")
  const balloon3 = require("../images/about-mexico/balloon3.svg")

  
  const updateWidthAndHeight = () => {
    if (window.innerWidth <= 600) {
      setBalloonDis(false)
    } else {
      setBalloonDis(true)
      setHBalloon1(document.getElementById("balloon1").offsetHeight)
      setWBalloon2(document.getElementById("balloon2").offsetWidth)
      setHBalloon2(document.getElementById("balloon2").offsetHeight)
      setWBalloon3(document.getElementById("balloon3").offsetWidth)
      setHBalloon3(document.getElementById("balloon3").offsetHeight)
    }
  }
  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight)
    return () => window.removeEventListener("resize", updateWidthAndHeight)
  })
  return (
    <>
      <Seo
        title={`メキシコ情報 | メキシコの求人・転職・就職情報【QUICK GLOBAL MEXICO】`}
      />
      <section className="container about-mexico">
        <div className="row">
          <div className="col-lg-9 col-lg-9">
            <div className="about-mexico__information-mexico">
              <SectionTitle>メキシコ情報</SectionTitle>
              <hr className="u-line-bottom" />
              <div className="about-mexico__information-mexico--info">
                <Img fluid={images[0].childImageSharp.fluid} />
                <p className="text-content">
                  クイックグローバルメキシコはメキシコのアグアスカリエンテス州にオフィスを構えております。現在、メキシコの中でもアグアスカリエンテスを含む
                  <a href="/info/bajio/" target="_blank" rel="noreferrer">
                    バヒオ地区
                  </a>
                  には日本から多くの企業が進出しています。このメキシコ情報ページではメキシコ国内の就職、転職などの様々な情報をお届けいたします。
                </p>
                <Img fluid={images[1].childImageSharp.fluid} />
              </div>
            </div>
            <div className="about-mexico__about-working">
              <SectionTitle2>メキシコで働く上でのよくある質問</SectionTitle2>
              <hr className="u-line-bottom" />
              <p className="text-content">
                メキシコ転職を希望される、日本在住者の方から頂く「よくある質問」について回答いたします。
              </p>
              <ol className="list-content">
                <li>
                  <button onClick={() => scrollTo("#section1")}>
                    治安について
                  </button>
                </li>
                <li>
                  <button onClick={() => scrollTo("#section2")}>
                    物価、生活について（アグアスカリエンテスの例）
                  </button>
                </li>
                <li>
                  <button onClick={() => scrollTo("#section3")}>
                    社会保険について
                  </button>
                </li>
                <li>
                  <button onClick={() => scrollTo("#section4")}>
                    給与の相場について
                  </button>
                </li>
                <li>
                  <button onClick={() => scrollTo("#section5")}>
                    就労ビザについて
                  </button>
                </li>
                <li>
                  <button onClick={() => scrollTo("#section6")}>
                    運転免許証について
                  </button>
                </li>
                <li>
                  <button onClick={() => scrollTo("#section7")}>
                    お問い合わせ先
                  </button>
                </li>
              </ol>
              <div className="about-mexico__about-working--descriptions">
                <h3 id="section1">１、治安について</h3>
                <p>
                  「メキシコ」と聞くと、治安が悪い国というイメージをお持ちの方も多いのではないでしょうか。{" "}
                  <br />
                  もちろん海外ですので、日本ほどの安全は保障できるものではありませんが、アグアスカリエンテスを含む、日系企業が多いとされるバヒオ地区はメキシコの中でも比較的治安が落ち着いていると言われています。{" "}
                  <br />
                  不用意に出歩かない、必要以上の貴重品は持ち歩かない等注意は必要ですが、基本的な防犯対策を行えば日常生活は問題なく送ることができます。
                </p>
                <h3 id="section2">
                  ２、物価、生活について（アグアスカリエンテスの例）
                </h3>
                <h4>アグアスカリエンテスの街並み</h4>
                <Img fluid={images[2].childImageSharp.fluid} />
                <span>セントロ</span>
                <Img fluid={images[3].childImageSharp.fluid} />
                <span>街並み</span>
                <Img fluid={images[4].childImageSharp.fluid} />
                <span>セントロの教会</span>
                <Img fluid={images[5].childImageSharp.fluid} />
                <span>ショッピングモール</span>
                <Img fluid={images[6].childImageSharp.fluid} />
                <span>
                  複合施設（クイックグローバルメキシコのオフィスも入っています）
                </span>
                <h4>物価について</h4>
                <p>
                  <b>
                    1ペソ＝7.6円（2023年5月時点）
                    <br />
                    1米ドル ＝ 約18ペソ ＝ 約136円（2023年5月時点）
                    <br />
                    <br />
                    家賃：5,000ペソ～15,000ペソ程度 <br />
                  </b>
                  5,000ペソ程度だと、ルームシェアが可能です。
                  <br />
                  7,000ペソ以上で、アパートや一軒家での一人暮らしが可能です。
                  <br />
                  家探しについては{" "}
                  <a
                    href="/info/10-points-for-finding-house/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    こちら
                  </a>
                  をご覧ください。
                  <br />
                  <b>食費：</b>
                  スーパーマーケットで販売されている野菜や果物、お肉などは日本よりも安いですが、日本食や魚介類は割高です。{" "}
                  <br />
                  外食の場合はタコス1個約15ペソ、メキシカンプレート100ペソ、日本食レストランでの定食200ペソ程度です。
                  <br />
                  物価は全体的に日本と比べると安く、給与が日本の平均以下だとしても一定の水準を保った生活を送ることができます。
                </p>
                <h4>食事について</h4>
                <p>
                  各地の大型スーパーマーケットには日本食材コーナーがあり、日系企業が進出している都市にはそれぞれ日本食材店や日本食レストランもあります。アグアスカリエンテスには、日本の食材が手に入るお店が数軒、日本食レストランは10軒ほどあります。また、メキシコ料理はもちろんイタリア料理、アルゼンチン料理なども楽しめます。
                </p>
                <Img fluid={images[7].childImageSharp.fluid} />
                <span>アグアスカリエンテスの大型スーパーマーケット</span>
                <Img fluid={images[8].childImageSharp.fluid} />
                <span>スーパーマーケット内部</span>
                <Img fluid={images[9].childImageSharp.fluid} />
                <span>スーパーマーケット内部</span>
                <Img fluid={images[10].childImageSharp.fluid} />
                <span>スーパーマーケット内部（日本食コーナー）</span>
                <Img fluid={images[11].childImageSharp.fluid} />
                <span>日本食材店</span>
                <Img fluid={images[12].childImageSharp.fluid} />
                <span>日本食レストラン</span>
                <Img fluid={images[13].childImageSharp.fluid} />
                <span>日本食</span>
                <Img fluid={images[14].childImageSharp.fluid} />
                <span>ゴルフバー</span>
                <Img fluid={images[15].childImageSharp.fluid} />
                <span>メキシコ料理レストラン</span>
                <Img fluid={images[16].childImageSharp.fluid} />
                <span>タコス</span>
                <h3 id="section3">３、社会保険について</h3>
                <p>
                  メキシコの社会保険はIMSSと呼ばれ、 <br />
                  Instituto Mexicano de Seguro
                  Social(社会保険公社)の頭文字を取ったものです。メキシコで働く場合、日本と同様にメキシコの社会保険へ必ず加入することになります。
                  メキシコでは社会保険料を納付することで下記の保険や社会福祉を受けることが出来ます。
                  メキシコの福利厚生について詳しくは
                  <a
                    href="/info/employee-benefits/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    こちら
                  </a>
                  から <br />
                </p>
                <ul className="about-mexico__list">
                  <li>医療保険</li>
                  <li>労災保険</li>
                  <li>障害保険/生命保険</li>
                  <li>社会福祉 /生命保険</li>
                </ul>
                <h4>社会保険　IMSSの病院事情</h4>
                <h5>メリット</h5>
                <p>
                  IMSSが提携している病院であれば、薬代含め、すべて無料です。
                  最新の設備が整っている病院もあります。
                </p>
                <h5>デメリット</h5>
                <p>
                  IMSSが提携している病院には多くの患者が訪れる為、診察は非常に時間がかかり、一日がかりになることもあります。
                </p>
                <h4>高額医療保険　適応額について</h4>
                <p>
                  企業によっては、福利厚生の一つとして会社負担で加入させてもらえる場合もあります。
                  私立の病院を使用した際に診察料・検査費用・治療費・薬代などの合計金額が一定の額を超えた場合に適用され、少額な医療費には適用されません。契約の種類により免責額が変わりますが、大体1,500ペソ～15,000ペソ（約11,500円〜115,000円）程度に設定されており、それを超える医療費に適用されます。また、免責額とは別にCoaseguro（コ・インシュランス/自己負担額）の割合も設定されており、免責額とCoaseguroを足した額が自分で支払う金額となります。ただCoaseguroにも上限額が設定されていますので、膨大な金額が自己負担になることはありません。
                  <br />
                  <br />
                  ※病気や手術内容により免責/自己負担額が設定されている場合がありますので、詳しいプランや利用方法については加入時に会社担当者や保険会社に確認することをお勧めします。
                </p>
                <div className="box-example2">
                  <b>アグアスカリエンテスの例</b> <br />
                  <b>風邪の場合:</b>
                  <br />
                  薬代含め　約400～1500ペソ→高額医療保険対象外
                  <br />
                  <b>出産の場合:</b>
                  <br />
                  約2万～5万ペソ→高額医療保険対象
                  <br />
                  <small>※州によって医療費の平均が異なります。</small>
                </div>  
                詳しくは「<a href="/info/if-you-get-sick" rel="noopener" target="_blank">メキシコで病気にかかったら</a>」をご参考にしてください。              
                <h3 id="section4">４、給与の相場について</h3>
                <p>
                  1ペソ＝7.6円（2023年5月時点）
                  <br />
                  1米ドル ＝ 約18ペソ ＝ 約136円（2023年5月時点）
                </p>
                <div style={{ overflowX: "auto" }}>
                  <table>
                    <thead>
                      <tr>
                        <th colSpan={4} className="dotted-line">
                          求人のおおよその水準
                        </th>
                        <th>ペソ</th>
                        <th>ドル</th>
                        <th>円</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td rowSpan={6} className="control1">
                          <p>新卒・第二新卒</p>
                        </td>
                        <td rowSpan={5}>{""}</td>
                        <td rowSpan={5}>{""}</td>
                        <td rowSpan={5} className="right-line">{""}</td>
                        <td className="control1">25,000ペソ</td>
                        <td className="control1">1,383ドル</td>
                        <td className="control1">190,250円</td>
                      </tr>
                      <tr>
                        <td>26,000ペソ</td>
                        <td>1,439ドル</td>
                        <td>197,860円</td>
                      </tr>
                      <tr>
                        <td>27,000ペソ</td>
                        <td>1,494ドル</td>
                        <td>205,470円</td>
                      </tr>
                      <tr>
                        <td>28,000ペソ</td>
                        <td>1,549ドル</td>
                        <td>213,080円</td>
                      </tr>
                      <tr>
                        <td>29,000ペソ</td>
                        <td>1,605ドル</td>
                        <td>220,690円</td>
                      </tr>
                      <tr>
                        <td rowSpan={6} className="top-line control2 balloon1">
                          <p>
                            中途 <br />
                            （業界・職種
                            <br />
                            未経験）
                          </p>
                          {balloondis && (
                            <img
                              src={balloon1}
                              alt="ballon1"
                              className="balloon"
                              id="balloon1"
                              style={{
                                marginTop: "-" + (hballoon1 / 2 - 40) + "px",
                                marginLeft: "-45px",
                              }}
                            />
                          )}
                        </td>
                        <td rowSpan={5} className="top-line">{""}</td>
                        <td rowSpan={5} className="top-line right-line">{""}</td>
                        <td className="top-line control2">30,000ペソ</td>
                        <td className="top-line control2">1,660ドル</td>
                        <td className="top-line control2">228,300円</td>
                      </tr>
                      <tr>
                        <td rowSpan={21}>{""}</td>
                        <td>31,000ペソ</td>
                        <td>1,715ドル</td>
                        <td>235,910円</td>
                      </tr>
                      <tr>
                        <td>32,000ペソ</td>
                        <td>1,771ドル</td>
                        <td>243,520円</td>
                      </tr>
                      <tr>
                        <td>33,000ペソ</td>
                        <td>1,826ドル</td>
                        <td>251,130円</td>
                      </tr>
                      <tr>
                        <td>34,000ペソ</td>
                        <td>1,881ドル</td>
                        <td>258,740円</td>
                      </tr>
                      <tr>
                        <td rowSpan={12} id="balloon2" className="top-line control3 balloon2">
                          <p>
                            中途 <br />
                            （業界・職種 <br />
                            経験者）
                          </p>
                          {balloondis && (
                            <img
                              src={balloon2}
                              alt="ballon2"
                              className="balloon"
                              id="balloon2"
                              style={{
                                marginLeft: "-" + wballoon2 + "px",
                                marginTop: "-" + hballoon2 / 2 + "px",
                              }}
                            />
                          )}
                        </td>
                        <td rowSpan={11} className="top-line right-line">{""}</td>
                        <td className="top-line control3">35,000ペソ</td>
                        <td className="top-line control3">1,937ドル</td>
                        <td className="top-line control3">266,350円</td>
                      </tr>
                      <tr>
                        <td rowSpan={16}>{""}</td>
                        <td>36,000ペソ</td>
                        <td>1,992ドル</td>
                        <td>273,960円</td>
                      </tr>
                      <tr>
                        <td>37,000ペソ</td>
                        <td>2,047ドル</td>
                        <td>281,570円</td>
                      </tr>
                      <tr>
                        <td>38,000ペソ</td>
                        <td>2,103ドル</td>
                        <td>289,180円</td>
                      </tr>
                      <tr>
                        <td>39,000ペソ</td>
                        <td>2,158ドル</td>
                        <td>296,790円</td>
                      </tr>
                      <tr>
                        <td>40,000ペソ</td>
                        <td>2,213ドル</td>
                        <td>304,400円</td>
                      </tr>
                      <tr>
                        <td>41,000ペソ</td>
                        <td>2,269ドル</td>
                        <td>312,010円</td>
                      </tr>
                      <tr>
                        <td>42,000ペソ</td>
                        <td>2,324ドル</td>
                        <td>319,620円</td>
                      </tr>
                      <tr>
                        <td>43,000ペソ</td>
                        <td>2,379ドル</td>
                        <td>327,230円</td>
                      </tr>
                      <tr>
                        <td>44,000ペソ</td>
                        <td>2,435ドル</td>
                        <td>334,840円</td>
                      </tr>
                      <tr>
                        <td>45,000ペソ</td>
                        <td>2,490ドル</td>
                        <td>342,450円</td>
                      </tr>
                      <tr>
                        <td
                          rowSpan={6}
                          className="right-line top-line control4 balloon3"
                        >
                          <p>中途即戦力</p>
                          {balloondis && (
                            <img
                              src={balloon3}
                              alt="ballon3"
                              className="balloon"
                              id="balloon3"
                              style={{
                                marginLeft: "-" + (wballoon3 + 20) + "px",
                                marginTop: "-" + hballoon3 / 2 + "px",
                              }}
                            />
                          )}
                        </td>
                        <td className="top-line control4">50,000ペソ</td>
                        <td className="top-line control4">2,767ドル</td>
                        <td className="top-line control4">380,500円</td>
                      </tr>
                      <tr>
                        <td rowSpan={5}>{""}</td>
                        <td>60,000ペソ</td>
                        <td>3,320ドル</td>
                        <td>456,600円</td>
                      </tr>
                      <tr>
                        <td>70,000ペソ</td>
                        <td>3,873ドル</td>
                        <td>532,700円</td>
                      </tr>
                      <tr>
                        <td>80,000ペソ</td>
                        <td>4,427ドル</td>
                        <td>608,800円</td>
                      </tr>
                      <tr>
                        <td>90,000ペソ</td>
                        <td>4,980ドル</td>
                        <td>684,900円</td>
                      </tr>
                      <tr>
                        <td>100,000ペソ</td>
                        <td>5,533ドル</td>
                        <td>761,000円</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p>
                  ※あくまでもおおよその数値です。 <br />
                  ※ここから税金が差し引かれる為、7~8割が手取り額になります。
                </p>
                <h3 id="section5">５、就労ビザについて</h3>
                <div className="box-example">
                  <ol>
                    <li>内定先企業による就労ビザ取得の申請（1〜3か月程度）</li>
                    <li>
                      申請が受理されると、書面が発行され在外メキシコ大使館で面接に臨んでいただきます。
                      <br />
                      <small>
                        ※日本での面接の場合は、当日に入国許可証（一時居住者用査証）がパスポートに付与されます。（この段階では「就労ビザ」ではなく、メキシコに入国できる許可が与えられた状態です。就労ビザの取得は渡航後に進めます。）
                      </small>
                    </li>
                    <li>メキシコ入国（入国許可証取得後180日以内）</li>
                    <li>
                      入国後、30日以内に弁護士を通して就労ビザの申請を行います。
                      <br />
                      <small>
                        ※申請後、1か月前後で取得できます。
                        <br />
                        ※就労ビザはメキシコでは公的な身分証明書として使用できます。就労ビザが取得でき次第、銀行口座開設や運転免許証等の申請を行うことができます。
                      </small>
                    </li>
                  </ol>
                </div>
                <h3 id="section6">６、運転免許証について</h3>
                <p>
                  アグアスカリエンテス州では、日本の運転免許を保有していれば運転が可能です。グアナファト州でも日本の免許証を翻訳した{" "}
                  <Link
                    to="https://www.leon.mx.emb-japan.go.jp/itpr_ja/00_000264.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    抜粋証明
                  </Link>
                  があれば運転できます（州によってはメキシコの免許証が必要です。）
                  <br />
                  ただし、万が一事故にあった際に保険が適応されない可能性があるため、入社する企業から現地の免許を取得してから運転するよう指示されることが一般的です。現地の運転免許証は就労ビザを取得後、申請所にて1~2日で取得できます。
                  <br />
                  <small>
                    ※アグアスカリエンテス州ではテスト等はなく、費用の支払いのみです。州によってはテストを受ける必要もあります。
                  </small>
                </p>
                <h3 id="section7">７、お問い合わせ先</h3>
                <p>
                  当社キャリアコーディネーターがご相談を承ります。お気兼ねなく下記フォームからお問い合わせ下さい。
                  <br />
                  最新の求人情報は
                  <a href="/jobs-listing" target="_blank" rel="noreferrer">
                    こちら
                  </a>
                  <br />
                  電話番号：+52-449-153-1400 <br />
                  メールアドレス：
                  <a
                    href="mailto:quick@919mexico.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    quick@919mexico.com
                  </a>
                </p>
                <h4>お問い合わせフォーム</h4>
                <ContactForm />
              </div>
            </div>

            <Contact />
          </div>
          <WrapperRight />
        </div>
      </section>
    </>
  )
}

export default AboutMexico
